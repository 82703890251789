<template>
  <div class="">
    <v-btn small color="primary" class="mr-1" @click="uploaddata = []; file = null; dialog = true">
      <v-icon class="mr-1">mdi-upload-multiple</v-icon>
      <span>Upload Data</span>
    </v-btn>
    <lb-dialog v-model="dialog" :width="(uploaddata.length > 0) ? (400 + (comparitive * 100)).toString() : '400'"
      heading="Bulk Upload Data" :loading="loading">
      <template v-slot:body>
        <div>
          <div class="text-center mb-1">Upload the amount for variance analysis here.</div>
          <div class="d-flex align-center justify-center">
            <lb-file v-model="file" label="" class="py-0 my-0" :loading="fileprocessing['file'] === 1" :drag="false"
              hidedetails @change="readFile('file')" :displayname="false" accept=".xlsx,.xls,.xlsm,.xlsb" />
            <v-btn color="primary" small class="ml-2"
              @click="$nova.downloadFile('', {}, baseurl + '/analysis/getuploaddatatemplate')">Download
              Template</v-btn>
          </div>
        </div>
        <div v-if="uploaddata.length > 0" class="mt-3">
          <v-simple-table dense class="">
            <template v-slot:default>
              <thead>
                <th class="text-left" scope="glcode">GL Code</th>
                <th class="text-right" scope="amount1">Amount 1</th>
                <th class="text-right" scope="amount2">Amount 2</th>
                <th class="text-right" scope="amount3" v-if="comparitive > 1">Amount 3</th>
                <th class="text-right" scope="amount4" v-if="comparitive > 2">Amount 4</th>
                <th class="text-right" scope="amount4" v-if="comparitive > 3">Amount 5</th>
              </thead>
              <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                <tr v-for="(v, k) in uploaddata" :key="k" class="">
                  <template v-for="(vv, kk) in v">
                    <td v-if="dataerror[k][kk]" :key="kk" class="error--text font-weight-bold">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-if="kk === 'glcode'" v-bind="attrs" v-on="on">{{ vv || 'Error' }}</div>
                          <div v-else class="text-right" v-bind="attrs" v-on="on">{{ vv || 'Error' }}</div>
                        </template>
                        <span>{{ dataerror[k][kk] }}</span>
                      </v-tooltip>
                    </td>
                    <td v-else :key="kk">
                      <div v-if="kk === 'glcode'">{{ vv }}</div>
                      <div v-else class="text-right">{{ $nova.formatNumber(vv) }}</div>
                    </td>
                  </template>
                </tr>
              </v-slide-y-transition>
            </template>
          </v-simple-table>
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <div v-if="uploaddata.length > 0">
          <v-btn small class="ml-2" color="primary" v-if="!errorflag" @click="uploadData()">Verify &
            Upload</v-btn>
          <div class="ml-2 error--text font-weight-bold" v-else>We found few errors. Rectify the same to proceed to upload
          </div>
        </div>
      </template>
    </lb-dialog>
  </div>
</template>

<script>

export default {
  name: 'fluxmanagement_component_upload_data',
  // props: ['props'],
  data: function () {
    return {
      // generateddescription: "",
      file: null,
      fileprocessing: {},
      errorflag: false,
      uploaddata: [],
      dataerror: [],
      dialog: false,
      loading: false,
      comparitive: 2,
    }
  },
  created() {
  },
  activated() {
    this.comparitive = this.config.comparitive || 2;
  },
  props: {
    right: {
      type: String,
      default: "fluxmanagement_analysis"
    },
    baseurl: {
      type: String,
      default: "/v2/fluxmanagement"
    },
    config: {
      type: Object,
      default: () => { }
    },
    project: {
      type: String,
      default: "x"
    },
    // required: {
    //   type: Boolean,
    //   default: false
    // }
  },
  methods: {
    init() {
    },
    readFile(key) {
      let file = this.file;
      if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
        let headings = ['GL Code', 'Amount 1', 'Amount 2', 'Amount 3', 'Amount 4', 'Amount 5'];
        this.loading = true;
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(dt => {
          if (JSON.stringify(dt[0] || []) === JSON.stringify(headings)) {
            let filedata = [];
            let errordata = [];
            this.errorflag = false;
            for (let i = 0; i < dt.length; i++) {
              if (i > 0) {
                const el = dt[i];
                let rowdata = { "glcode": el[0].toString(), "amount1": Number(el[1]), "amount2": Number(el[2]) };
                if (this.comparitive > 1) rowdata["amount3"] = Number(el[3]);
                if (this.comparitive > 2) rowdata["amount4"] = Number(el[4]);
                if (this.comparitive > 3) rowdata["amount5"] = Number(el[5]);
                let errors = {};
                if ((rowdata.glcode || "").toString() === "") errors["glcode"] = "GL Code is required";
                if ((rowdata.amount1 ?? "").toString() === "") errors["amount1"] = "Amount 1 is required";
                if ((rowdata.amount2 ?? "").toString() === "") errors["amount2"] = "Amount 2 is required";
                if (this.comparitive > 1 && (rowdata.amount3 ?? "").toString() === "") errors["amount3"] = "Amount 3 is required";
                if (this.comparitive > 2 && (rowdata.amount4 ?? "").toString() === "") errors["amount4"] = "Amount 4 is required";
                if (this.comparitive > 3 && (rowdata.amount5 ?? "").toString() === "") errors["amount5"] = "Amount 5 is required";
                if (Object.keys(errors).length > 0) this.errorflag = true;
                filedata.push(rowdata);
                errordata.push(errors);
              }
            }
            this.uploaddata = filedata;
            this.dataerror = errordata;
            this.dialog = true;
          } else throw Error("Columns '" + headings.join("','") + "' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.file = null;
          this.fileprocessing[key] = 0;
          this.loading = false;
        });
      }
    },
    uploadData() {
      this.loading = true;
      this.axios.post(this.baseurl + "/analysis/adddata/" + this.project, { data: this.uploaddata }).then(dt => {
        if (dt.data.status === "success") {
          this.dialog = false;
          this.$emit('reload');
          this.$store.commit("sbSuccess", "data uploaded successfully");
        } else throw dt.data.message || "Error uploading data";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  watch: {
  }
}
</script>
